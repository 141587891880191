import React, { useState, useMemo, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { Tooltip, IconButton, TextField, Button, Typography, Box } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Stack } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import { prettifyRut, calculateDv } from 'react-rut-formatter';
import Delete from '@mui/icons-material/Delete';
import UploadDropZoneDialogContainer from '../containers/UploadDropZoneDialogContainer';
import { ADD_COMPANIES_TO_BLACKLIST, GET_COMPANY_BLACKLIST, ADD_COMPANIES_TO_BLACKLIST_EXCEL } from '../../graphql/customers';
import { FingoDialog } from '../dialogs';
import { useBooleanState } from '../../hooks';
import { ValidationTextFieldInput } from '.';

const BlacklistAddCompanyMenu = () => {
  const [openMenu, setOpenMenu] = useBooleanState();
  const [comments, setComments] = useState('');
  const [files, setFiles] = useState([{ name: '' }]);
  const [error, setError] = useState(null);
  const [rutsList, setRutsList] = useState([{
    raw: '',
    pretty: '',
    withoutDv: '',
    isValid: true,
  }]);
  const rawRutList = useMemo(() => rutsList.map((rut) => rut.withoutDv), [rutsList]);
  const handleClose = () => {
    setComments('');
    setRutsList([{
      raw: '',
      pretty: '',
      withoutDv: '',
      isValid: true,
    }]);
    setOpenMenu(false);
    setFiles([{ name: '' }]);
  };
  const [addCompaniesFromBlacklistExcel, { loadingExcel }] = useMutation(
    ADD_COMPANIES_TO_BLACKLIST_EXCEL,
    { variables: { file: files[0] },
      onCompleted: handleClose,
      refetchQueries: [GET_COMPANY_BLACKLIST],
      awaitRefetchQueries: true,
    },
  );
  const [addCompaniesFromBlacklist, { loading }] = useMutation(
    ADD_COMPANIES_TO_BLACKLIST,
    { variables: {
      searchType: 'rut',
      identifiers: rawRutList,
      comments: Array(rawRutList.length).fill(comments),
    },
    onCompleted: handleClose,
    refetchQueries: [GET_COMPANY_BLACKLIST],
    awaitRefetchQueries: true,
    },
  );
  const handleAddRut = () => {
    setRutsList((prev) => [...prev, {
      raw: '',
      pretty: '',
      withoutDv: '',
      isValid: true,
    }]);
  };
  const handleChangeRut = (rut, i) => {
    const formattedRut = prettifyRut(rut);
    // eslint-disable-next-line no-void
    const rutWithoutDv = formattedRut === null || formattedRut === void 0 ? void 0 : formattedRut.slice(0, -1).replace(/\./g, '');
    const dv = formattedRut.charAt(formattedRut.length - 1);
    const isValid = rutWithoutDv !== ''
          && rutWithoutDv !== '0-'
          && dv === calculateDv(parseInt(rutWithoutDv, 10));
    setRutsList((previous) => {
      const newRutsList = [...previous];
      newRutsList[i] = {
        raw: formattedRut !== '0-0' ? `${rutWithoutDv}` : '',
        withoutDv: rutWithoutDv.slice(0, -1),
        pretty: formattedRut !== '0-0' ? formattedRut : '',
        isValid,
      };
      return newRutsList;
    });
  };
  const handleRemoveRut = (i) => {
    setRutsList((prev) => prev.slice(0, i).concat(prev.slice(i + 1, prev.length)));
  };
  const allRutsValid = useMemo(() => rutsList.every((rut) => rut.isValid && rut.raw), [rutsList]);
  const disabledConfirmed = useMemo(() => {
    if (files[0].name) return false;
    if (allRutsValid && rutsList.length) return false;
    return true;
  }, [files, rutsList, allRutsValid]);
  const confirmButtonAction = useCallback(() => {
    if (files[0].name) return addCompaniesFromBlacklistExcel();
    return addCompaniesFromBlacklist();
  });
  return (
    <>
      <Tooltip title="Agregar Empresas a la Blacklist">
        <span>
          <IconButton
            onClick={setOpenMenu}
            color="primary"
            id="remove-company-blacklist"
          >
            <AddCircleIcon />
          </IconButton>
        </span>
      </Tooltip>
      <FingoDialog
        title="Agregar Empresas a la Blacklist"
        open={openMenu}
        handleClose={handleClose}
        maxWidth="md"
        fullWidth
        dialogActionButton={(
          <LoadingButton
            color="primary"
            variant="contained"
            size="small"
            onClick={confirmButtonAction}
            disabled={disabledConfirmed}
            loading={loading || loadingExcel}
          >
            Confirmar
          </LoadingButton>
        )}
      >
        <Stack direction="column" spacing={2} alignItems="center" alignContent="center">
          <Typography variant="h2">
            Selecciona el archivo .xlsx con los campos:
          </Typography>
          <Stack direction="row" spacing={6}>
            <Box sx={{ fontFamily: 'Monospace' }}>rut</Box>
            <Box sx={{ fontFamily: 'Monospace' }}>comentario</Box>
          </Stack>
          <Stack sx={{ width: '70%' }}>
            <UploadDropZoneDialogContainer
              files={files}
              setFiles={setFiles}
              errorMutation={error}
              setErrorMutation={setError}
            />

          </Stack>
          <Typography variant="h2">
            O ingresar los ruts directamente:
          </Typography>
          <Stack sx={{ width: '25%' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddRut}
              size="small"
              id="uploadDocumentAdd"
            >
              Agregar Rut
            </Button>
          </Stack>
          {rutsList.map((rut, i) => (
            <Stack direction="row" spacing={1} alignItems="center">
              <ValidationTextFieldInput
                name="rut"
                type="text"
                validationtype="rut"
                label="Rut Empresa"
                variant="standard"
                value={rut.pretty}
                onChange={(e) => handleChangeRut(e.target.value, i)}

              />
              <IconButton onClick={() => handleRemoveRut(i)} size="small">
                <Delete />
              </IconButton>
            </Stack>
          ))}
          <TextField
            label="Comentarios"
            variant="outlined"
            name="Comentarios"
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            minRows="3"
            multiline
            sx={{ width: '80%' }}
          />
        </Stack>
      </FingoDialog>
    </>
  );
};

export default BlacklistAddCompanyMenu;

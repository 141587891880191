import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel } from '@mui/material';

const ControlledCheckboxInput = ({ value, onChange, label }) => (
  <FormControlLabel control={<Checkbox checked={value} onChange={onChange} />} label={label} />
);

ControlledCheckboxInput.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

ControlledCheckboxInput.defaultProps = {
  value: null,
};

export default ControlledCheckboxInput;

import React, { useState, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { Tooltip, IconButton, Menu, MenuItem, ListItemText, Box } from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { Stack } from '@mui/system';
import { GET_COMPANY_BLACKLIST, REMOVE_COMPANY_FROM_BLACKLIST } from '../../graphql/customers';
import LoadingIconButton from '../buttons/LoadingIconButton';

const BlacklistRemoveCompanyMenu = ({ masterEntityIds, setMasterEntityIds }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const closeMenu = useCallback(() => setAnchorEl(null), []);
  const openMenu = useCallback((event) => setAnchorEl(event.currentTarget), []);
  const [removeCompaniesFromBlacklist, { loading }] = useMutation(
    REMOVE_COMPANY_FROM_BLACKLIST,
    {
      variables: { ids: masterEntityIds },
      onCompleted: () => {
        closeMenu();
        setMasterEntityIds([]);
      },
      refetchQueries: [GET_COMPANY_BLACKLIST],
      awaitRefetchQueries: true,
    },
  );
  return (
    <>
      <Tooltip title="Eliminar Empresas de la Blacklist">
        <span>
          <IconButton
            onClick={openMenu}
            disabled={!masterEntityIds.length}
            color="primary"
            id="remove-company-blacklist"
          >
            <RemoveCircleIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Menu
        sx={{ display: 'inline' }}
        anchorEl={anchorEl}
        onClose={closeMenu}
        open={Boolean(anchorEl)}
        id="remove-company-blacklist-menu"
      >
        <MenuItem>
          <Stack direction="column">
            <ListItemText
              primary="Estas por eliminar las empresas seleccionadas de la Blacklist"
              secondary="¿Quieres continuar?"
            />
            <Box sx={{ alignSelf: 'center' }}>
              <LoadingIconButton onClick={removeCompaniesFromBlacklist} loading={loading} color="success" size="small">
                <CheckCircleRoundedIcon />
              </LoadingIconButton>
              <IconButton onClick={closeMenu} color="error" size="small">
                <CancelRoundedIcon />
              </IconButton>
            </Box>
          </Stack>
        </MenuItem>
      </Menu>
    </>
  );
};

BlacklistRemoveCompanyMenu.propTypes = {
  masterEntityIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  setMasterEntityIds: PropTypes.func.isRequired,
};

export default BlacklistRemoveCompanyMenu;

import { Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const UploadFileMiniInput = ({
  accept,
  onChange,
  label,
  placeHolder,
  ...props
}) => (
  <>
    {label !== '' && (
      <Typography htmlFor="contained-button-file" mb={1}>
        {label}
      </Typography>
    )}
    <input
      accept={accept}
      style={{ display: 'none' }}
      id="contained-button-file"
      type="file"
      onChange={onChange}
    />
    <label htmlFor="contained-button-file">
      <Button {...props}>{placeHolder}</Button>
    </label>
  </>
);

UploadFileMiniInput.propTypes = {
  accept: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeHolder: PropTypes.string,
  label: PropTypes.string,
};

UploadFileMiniInput.defaultProps = {
  accept: '.pem, .p12, .pfx',
  label: '',
  placeHolder: 'Selecciona un archivo',
};

export default UploadFileMiniInput;

import React, { useCallback, useEffect, useState } from 'react';
import { TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { moneyToNumber, moneyInputFormat, moneyFormats } from '../../helpers';

const MoneyInput = ({ value, setValue, currency, ...restProps }) => {
  const { t } = useTranslation();
  const selectedCurrency = currency || t('default currency');
  const format = moneyFormats[selectedCurrency];
  const { decimalCount, decimal, thousands, symbol } = format;
  const [moneyValue, _setMoneyValue] = useState(moneyInputFormat(
    value,
    decimalCount,
    decimal,
    thousands,
  ));
  const setMoneyValue = useCallback((newValue) => {
    const number = moneyToNumber(newValue, decimalCount, decimal, thousands);
    if (newValue.slice(-1) === decimal || newValue == null) {
      _setMoneyValue(newValue);
      return;
    }
    let multipier = 1;
    if (newValue.slice(-1) === '-') {
      multipier = -1;
    }
    setValue(number * multipier);
    if (number === 0 && multipier === -1) {
      _setMoneyValue('-0');
    } else {
      _setMoneyValue(moneyInputFormat(
        number * multipier,
        decimalCount,
        decimal,
        thousands,
      ));
    }
  }, []);

  const handleChange = useCallback((event) => {
    const { value: targetValue } = event.target;
    setMoneyValue(targetValue);
  }, [setMoneyValue]);
  useEffect(() => {
    _setMoneyValue(moneyInputFormat(
      value,
      decimalCount,
      decimal,
      thousands,
    ));
  }, [value]);
  return (
    <TextField
      value={moneyValue}
      onChange={handleChange}
      InputProps={{
        startAdornment: (
          <Typography>{symbol}</Typography>
        ),
      }}
      {...restProps}
    />
  );
};

MoneyInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  setValue: PropTypes.func.isRequired,
  currency: PropTypes.string,
};

MoneyInput.defaultProps = {
  currency: null,
};

export default MoneyInput;
